import React from 'react'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { state } from '../../store/state'

import './style.css'

export const ToastNotifications: React.FC = (props) => {
  const Msg = () => {
    const toast: any = Object.assign({}, props.toast)
    return <div>{toast}</div>
  }

  state.lastNotification.listen((value) => {
    toast(<Msg />, {
      toastId: value.id,
      position: 'bottom-center',
      autoClose: value.autoClose || 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  })

  return (
    <ToastContainer
      toastStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      limit={3}
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      transition={Slide}
    />
  )
}
